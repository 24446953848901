import classnames from 'classnames';
import { PageProps } from 'gatsby';
import React, { useState } from 'react';
import { Footer } from '../components/footer';
import { Marquee } from '../components/marquee/marquee';

const Index: React.FC<PageProps> = () => {
  const [maskTopActive, setMaskTopActive] = useState(false);

  return (
    <div className="page style-1">
      <Marquee font="serif">
        <h1>Philip Mancarella</h1>
      </Marquee>
      <Marquee speed={0.2} font="sans-serif" uppercase size={100}>
        <h2>Pianist, Komponist, </h2>
      </Marquee>
      <Marquee dir="right" speed={0.09} size={180} font="serif">
        <a href="mailto:info@philipmancarella.de">info@philipmancarella.de</a>
      </Marquee>
      <Marquee dir="left" speed={0.23} size={400} font="sans-serif" uppercase>
        <strong>
          #Jazz #Elektronische Musik #Performance #Tanz #Chiptunes
        </strong>
      </Marquee>
      <div className="content">
        {/* <div className="section">
          <h3>Jetzt</h3>
          <p>
            Jetzt im Moment übe ich Klavier. Vor 4 Tagen habe ich noch das
            getan: ein Konzert gegeben.
            <a href="https://google.de">Und was habe ich davor getan??</a>
          </p>
        </div> */}

        {/* <div className="section">
          <h3>Biografie</h3>
          <p>
            Ich bin Pianist, Komponist und Programmierer. Meine großen
            Inspirationen sind Freiheit und Ironie. Er studierte von 2004–2008
            an der ArtEZ Hoogeschool vor de Kunsten, Arnhem Jazz-Piano. Neben
            Projekten und Auftritten mit internationalen Künstlern und Bands in
            Europa und Russland wirkt und arbeitet er als Produzent und Musiker
            regelmäßig für MIRA und andere Künstler, Künstlergruppen,
            Theaterensembles und TänzerInnen. Er komponiert und produziert in
            den Bereichen elektronische Musik, Jazz und freie Musik für
            verschiedene Ensembles.
          </p>
        </div> */}
      </div>
      <Footer />
      <div className={classnames('mask top', { active: maskTopActive })}></div>
      <div className="mask bottom active"></div>
    </div>
  );
};

export default Index;
