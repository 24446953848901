import React, {
  ComponentType,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAnimationFrame } from '../../hooks/animation';
import './marquee.scss';

interface Props {
  speed?: number;
  dir?: 'left' | 'right';
  size?: number;
  font?: 'serif' | 'sans-serif';
  uppercase?: boolean;
}

export const Marquee: ComponentType<Props> = (props) => {
  const {
    children,
    size = 60,
    font = 'sans-serif',
    uppercase = false,
    dir = 'left',
    speed = 0.1,
  } = props;

  const [position, setPosition] = useState(0);
  const [originalWidth, setOriginalWidth] = useState(0);
  const [replicas, setReplicas] = useState<ReactNode>(null);

  const contentRef = useRef<HTMLDivElement>();

  const sf = useMemo(() => {
    if (typeof window !== 'undefined' && window?.innerWidth < 520) {
      return 0.5;
    }

    if (typeof window !== 'undefined' && window.innerWidth < 800) {
      return 0.75;
    }

    return 1;
  }, [typeof window !== 'undefined' ? window.innerWidth : undefined]);

  useEffect(() => {
    setOriginalWidth(contentRef.current.offsetWidth);
  }, []);

  useAnimationFrame(
    (deltaTime) => {
      if (originalWidth) {
        setPosition(
          (previousPosition) =>
            ((previousPosition +
              deltaTime * (dir === 'left' ? -1 : 1) * speed * 0.1) %
              originalWidth) -
            (dir === 'right' ? originalWidth : 0)
        );
      }
    },
    [originalWidth, speed, dir]
  );

  useEffect(() => {
    if (originalWidth > 0) {
      const numReplicas = Math.ceil((window.innerWidth * 2) / originalWidth);
      setReplicas(Array.from({ length: numReplicas }, () => children));
    }
  }, [originalWidth, children]);

  return (
    <div className="marquee">
      <div
        className="marquee-content"
        ref={contentRef}
        style={{
          fontSize: size * sf,
          transform: `translate(${Math.round(position)}px, 0)`,
          fontFamily: font,
          textTransform: uppercase ? 'uppercase' : 'none',
        }}
      >
        {children}
        {replicas}
      </div>
    </div>
  );
};
